<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary text-primary mb-0">
          แนะนำเพื่อน
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-2">
        <h3 class="text mb-0">
          ลิงก์ชวนเพื่อน
        </h3>

        <button
          class="btn-clipboard"
          @click="copyRef"
        >
          <i class="fal fa-copy" /> คัดลอก
        </button>
      </div>

      <p class="mb-0 text-primary">
        {{ ref }}
      </p>

      <div class="text-center mt-4">
        <span class="bg-body font-medium-3 p-75"> คอมมิชชั่น </span>
        <hr class="border-top-3 mt-n75">
      </div>

      <div class="row">
        <div class="col-6 p-0">
          <div class="card-withdraw mt-50 border-0 mb-2 p-75 mr-50">
            <div>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <p class="mb-0 text-primary">
                  สมัคร
                </p>

                <i
                  class="far fa-redo"
                  @click="GetInviteDetail()"
                />
              </div>

              <div class="d-flex justify-content-between">
                <h2 class="mb-0 text">
                  {{ InviteData.Find ? InviteData.Find.invite_user_total : 0 }}
                </h2>

                <p class="mb-0 text-primary mt-25">
                  คน
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 p-0">
          <div class="card-withdraw mt-50 border-0 p-75 ml-50">
            <div>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <p class="mb-0 text-primary">
                  การสั่งซื้อ
                </p>

                <i
                  class="far fa-redo"
                  @click="GetInviteDetail()"
                />
              </div>

              <div class="d-flex justify-content-between">
                <h2 class="mb-0 text">
                  {{
                    InviteData.Find.invite_buy_count
                      ? InviteData.Find.invite_buy_count
                      : 0
                  }}
                </h2>

                <p class="mb-0 text-primary mt-25">
                  ชุด
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 p-0">
          <div class="card-list p-puple border-0 mb-2 p-75">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mb-0 text-white">
                  ค่าคอมมิชชั่นทั้งหมด
                </p>
                <h2 class="mb-0 txt-sh">
                  {{
                    InviteData.Find.invite_commission
                      ? Commas(InviteData.Find.invite_commission)
                      : 0
                  }}
                  <small>₩</small>
                </h2>
              </div>

              <button
                class="btn-clipboard rounded text-primary"
                @click="ReceiveCom"
              >
                รับเงิน
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <h4 class="text mb-1">
          ประวัติการชวนเพื่อน
        </h4>

        <div
          v-if="InviteData.FindFriend.length === 0"
          class="text-center my-4"
        >
          <img
            src="/icon/friend.png"
            alt="friend"
            height="75"
          >

          <p class="mb-0 text">
            ยังไม่มีประวัติการชวนเพื่อน
          </p>
        </div>

        <div v-else>
          <div
            v-for="item in InviteData.FindFriend"
            :key="item.tel"
            class="htry-list mb-2 cursor-pointer"
            @click="lottoFriend(item)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <small class="mb-0 text-primary"> ชื่อผู้ใช้ </small>
                <h4 class="text-primary">
                  {{ item.username }}
                </h4>
              </div>

              <h4 class="mb-0 text-primary">
                {{ item.mylotto_count }} ชุด
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal claim reward -->
    <b-modal
      id="modal-claim-reward"
      title="รับเงินคอมมิชชั่น"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div>
        <div class="text-center my-50">
          <h3 class="mb-0 text-primary">
            รับค่าคอมมิชชั่นเรียบร้อย!
          </h3>

          <img
            src="/icon/received.png"
            alt="hand-money"
            class="my-75"
            height="75"
          >

          <div class="text-center my-50">
            <button
              class="btn-clipboard w-25 rounded text-primary p-50"
              @click="$bvModal.hide('modal-claim-reward')"
            >
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal friend buy -->
    <b-modal
      ref="modal-friend"
      hide-footer
      hide-header
      centered
    >
      <div class="hist-friend mt-1">
        <i
          class="far fa-times"
          @click="$refs['modal-friend'].hide()"
        />

        <h5 class="mb-0">
          ประวัตการซื้อ
        </h5>
        <h4 class="text-muted">
          {{ history.tels }}
        </h4>
        <loader
          v-if="Loadmore"
          object="#9658cc"
          color1="#532a76"
          color2="#d3bae9"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="30"
          name="circular"
        />

        <div
          v-for="(item, index) in lottoFriends"
          :key="index"
          class="htry-list mb-1 cursor-pointer p-1"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4
              class="text-primary mb-0"
              style="letter-spacing: 5px"
            >
              {{ item.lottonumber }}
            </h4>

            <small class="mb-0 text-muted">
              {{ item.roundth }}
            </small>
          </div>
        </div>

        <div class="demo-spacing-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            align="center"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            @input="lottoFriend()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-modal>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ThisHeader from './components/ThisHeader.vue'
// import LoadingHome from './components/LoadingHome.vue'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    // LoadingHome,
    ThisFooter,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 3,
      rows: 1,
      Loadmore: false,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      ref: '',
      balance: 0,
      history: [],
      balance_withdraw: 0,
      balance_datecheck: '',
      InviteData: {
        FindFriend: [],
        Find: {
          invite_user_total: 0,
          invite_buy_count: 0,
          invite_commission: 0,
        },
      },
      agent: null,
      lottoFriends: [],
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.GetInviteDetail()
    this.GetAgent()
  },
  methods: {
    async lottoFriend(item) {
      if (item) {
        this.history = item
      }
      this.Loadmore = true
      this.$refs['modal-friend'].show()
      // console.log(item)
      const obj = {
        username: this.history.username,
        page: this.currentPage,
        perpage: this.perPage,
      }
      // console.log(obj)
      this.$http
        .post('lotto-suvarn/Invite-Friend/DetailFriend', obj)
        .then(ress => {
          // console.log(ress.data.lottery)
          this.Loadmore = false
          this.lottoFriends = ress.data.lottery
          this.rows = ress.data.total
          this.history.tels = `${item.tel.slice(0, 3)}-XXX-XXXX`

          // console.log(this.telfriend)
          this.$bvModal.show('modal-friend')
        })
        .catch(err => {
          this.SwalError(err.response.data.message)
        })
    },
    ReceiveCom() {
      this.$http
        .get('lotto-suvarn/Invite-Friend/Receive')
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          // console.log(ress.data)
          this.GetInviteDetail()
          this.getBalance()
          // this.Success(ress.data.message)

          this.$bvModal.show('modal-claim-reward')
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
          this.$bvModal.hide('modal-claim-reward')
        })
    },
    async GetInviteDetail() {
      this.Loadmore = true
      this.$http
        .get('lotto-suvarn/Invite-Friend')
        .then(async ress => {
          await this.sleep(500)
          // console.log(ress.data)
          this.InviteData = ress.data
          this.Loadmore = false
          // console.log(this.InviteData)
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    GetAgent() {
      this.$http
        .get('lotto-suvarn/agent/show')
        .then(ress => {
          this.agent = ress.data
          this.ref = `${ress.data.site}/register?ref=${this.userData.invite_code}`
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    copyRef() {
      // copy ref
      const el = document.createElement('textarea')
      el.value = this.ref
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
        },
      })
    },
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    getBalance() {
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.balance = ress.data
        this.balance_datecheck = moment()
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
